import React from 'react';
import EPKBaseComponent from '../EPKFoundation/EPKBaseComponent';

class EPKText extends EPKBaseComponent {

  ruleset = {
    
  }

  renderComponent(dynamicStyles) {
    return <text style={{...dynamicStyles}}>
        {this.props.text}
    </text>;
  }
}

export default EPKText;