import React from 'react';
import EPKBaseComponent from '../../EPKFoundation/EPKBaseComponent';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is included

class EPKHorizontalStack extends EPKBaseComponent {

  ruleset = {
    
  }

  renderComponent(dynamicStyles) {
    let alignment = 'center';
    if (this.props.verticalAlignment !== undefined) {
      alignment = this.getBootstrapAlignment(this.props.verticalAlignment);
    }

    dynamicStyles['display'] = 'flex';

    const onClick = dynamicStyles['onClick']
    dynamicStyles['onClick'] = undefined;

    return (
      <div
        onClick={onClick}
        className={this.props.viewType}
        // onClick={this.dynamicStyles['onClick']}
        style={{
          display: "flex",
          flexDirection: "row", /* Use row if you want horizontal layout */
          height: "100%", /* or any specific height */
          width: "100%",  /* or any specific width */
          boxSizing: "border-box" /* Include padding in the height/width calculation */
        }}
      >
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        height: "100%",
        alignItems: alignment,
        justifyContent: "center",
        overflow: "visible",
        ...dynamicStyles}}>
        {this.renderChildren()}
      </div>
      </div>
    );
  }

  getBootstrapAlignment(alignment) {
    switch (alignment) {
      case "top":
        return "start";
      case "bottom":
        return "end";
      default:
        return "center";
    }
  }
}

export default EPKHorizontalStack;