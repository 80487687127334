import React from 'react';
import Rectangle from '../EPKComponents/EPKRectangle';
import EPKText from '../EPKComponents/EPKText';
import EPKHorizontalStack from '../EPKComponents/Stacks/EPKHorizontalStack';
import EPKVerticalStack from '../EPKComponents/Stacks/EPKVerticalStack';
import EPKSpacer from '../EPKComponents/EPKSpacer';
import EPKImage from '../EPKComponents/Image/Image/EPKImage';
import EPKWrappingHStack from '../EPKComponents/Stacks/EPKWrappingHStack';
import EPKYoutubePlayer from '../EPKComponents/EPKYoutubePlayer';
import EPKRoot from '../EPKComponents/Stacks/EPKRoot';
import EPKScrollView from '../EPKComponents/Stacks/EPKScrollView';
import EPKSocialMedia from '../EPKComponents/SocialMedia/EPKSocialMedia';
import EPKSFSymbol from '../EPKComponents/Image/SFSymbol/EPKSFSymbol';

const EPKJsonRenderer = ({ data }) => {
  let component = data;
  const { componentName, attributes, children, viewType } = component;
  switch (componentName) {
    case 'root':
      return <EPKRoot attributes={attributes} children={children} viewType={viewType}/>;
    case 'verticalStack':
      return <EPKVerticalStack attributes={attributes} children={children} viewType={viewType} horizontalAlignment={component.horizontalAlignment} />;
    case 'horizontalStack':
      return <EPKHorizontalStack attributes={attributes} children={children} viewType={viewType} verticalAlignment={component.verticalAlignment} />;
    case 'wrappingHStack':
      return <EPKWrappingHStack attributes={attributes} children={children} viewType={viewType} />;
    case 'rectangle':
      return <Rectangle attributes={attributes} children={children} viewType={viewType} />;
    case 'text':
    case 'fixedText':
      return <EPKText attributes={attributes} children={children} viewType={viewType} text={component.text}/>;
    case 'spacer':
      return <EPKSpacer attributes={attributes} children={children} viewType={viewType} />;
    case 'image':
      return <EPKImage attributes={attributes} children={children} viewType={viewType} imageId={component.imageId}/>;
    case 'youtubeVideo':
      return <EPKYoutubePlayer attributes={attributes} children={children} viewType={viewType} youtubeURL={component.youtubeURL}/>;
    case 'scrollView':
      return <EPKScrollView attributes={attributes} children={children} viewType={viewType} direction={component.direction}/>;
    case 'socialMedia':
      return <EPKSocialMedia attributes={attributes} children={children} viewType={viewType} type={component.type} />;
    case 'sfSymbol':
      return <EPKSFSymbol attributes={attributes} children={children} viewType={viewType} systemName={component.systemName} ></EPKSFSymbol>
    default:
      return null;
  }
};

export default EPKJsonRenderer;