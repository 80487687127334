import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EPKJsonRenderer from './EPKFoundation/EPKJsonRenderer';
import './EPKComponents/Stacks/EPKScrollView.css'

const description = {
  "attributes" : [
    {
      "bottom" : "base",
      "attributeName" : "padding",
      "top" : "base",
      "trailing" : "base",
      "leading" : "base"
    }
  ],
  "componentName" : "verticalStack",
  "children" : [
    {
      "attributes" : [
        
      ],
      "verticalAlignment" : "center",
      "children" : [
        {
          "attributes" : [
            {
              "attributeName" : "scaledToFit"
            },
            {
              "attributeName" : "frame",
              "width" : "tiny",
              "height" : "tiny"
            }
          ],
          "children" : [

          ],
          "systemName" : "person.fill",
          "componentName" : "sfSymbol"
        },
        {
          "children" : [

          ],
          "componentName" : "fixedText",
          "attributes" : [
            {
              "attributeName" : "font",
              "size" : "headline",
              "type" : "Montserrat"
            },
            {
              "weight" : "medium",
              "attributeName" : "fontWeight"
            },
            {
              "attributeName" : "color",
              "r" : 1,
              "g" : 1,
              "b" : 1,
              "a" : 1
            }
          ],
          "text" : "Sobre"
        },
        {
          "componentName" : "spacer",
          "attributes" : [

          ],
          "children" : [

          ]
        }
      ],
      "componentName" : "horizontalStack"
    },
    {
      "attributes" : [
        {
          "attributeName" : "background",
          "g" : 0.133,
          "a" : 1,
          "r" : 0.133,
          "b" : 0.133
        },
        {
          "topRight" : 8,
          "attributeName" : "clipShapeRectangle",
          "bottomRight" : 8,
          "topLeft" : 8,
          "bottomLeft" : 8
        }
      ],
      "horizontalAlignment" : "center",
      "componentName" : "verticalStack",
      "children" : [
        {
          "children" : [

          ],
          "componentName" : "text",
          "attributes" : [
            {
              "attributeName" : "font",
              "size" : "callout",
              "type" : "Montserrat"
            },
            {
              "leading" : "big",
              "trailing" : "big",
              "top" : "big",
              "bottom" : "big",
              "attributeName" : "padding"
            },
            {
              "alignment" : "leading",
              "attributeName" : "frameMaxWidth"
            },
            {
              "attributeName" : "color",
              "g" : 0.447,
              "b" : 0.447,
              "a" : 1,
              "r" : 0.447
            }
          ],
          "text" : "Testando isso aqui em",
          "editingLabel" : "Sua descrição aqui."
        }
      ]
    }
  ],
  "horizontalAlignment" : "leading",
  "editingMode" : "descriptionView",
  "viewType" : "about"
};

const header = {
  "componentName" : "verticalStack",
  "editingMode" : "headerView",
  "horizontalAlignment" : "center",
  "attributes" : [
    {
      "top" : "none",
      "bottom" : "medium",
      "attributeName" : "padding",
      "leading" : "none",
      "trailing" : "none"
    },
    {
      "g" : 0.125,
      "attributeName" : "background",
      "b" : 0.125,
      "r" : 0.125,
      "a" : 1
    },
    {
      "topLeft" : 0,
      "attributeName" : "clipShapeRectangle",
      "topRight" : 0,
      "bottomRight" : 20,
      "bottomLeft" : 20
    }
  ],
  "children" : [
    {
      "attributes" : [
        {
          "attributeName" : "scaledToFill"
        },
        {
          "attributeName" : "frame",
          "height" : "bannerHeight"
        },
        {
          "attributeName" : "frameMaxWidth",
          "alignment" : "center"
        },
        {
          "topLeft" : 0,
          "topRight" : 0,
          "bottomLeft" : 21,
          "attributeName" : "clipShapeRectangle",
          "bottomRight" : 21
        }
      ],
      "componentName" : "image",
      "children" : [
        {
          "children" : [
            {
              "children" : [

              ],
              "attributes" : [
                {
                  "alignment" : "center",
                  "attributeName" : "frameMaxWidth"
                }
              ],
              "componentName" : "spacer"
            },
            {
              "children" : [

              ],
              "attributes" : [
                {
                  "attributeName" : "scaledToFill"
                },
                {
                  "attributeName" : "frame",
                  "height" : "profile",
                  "width" : "profile"
                },
                {
                  "topLeft" : 13,
                  "topRight" : 13,
                  "bottomLeft" : 13,
                  "attributeName" : "clipShapeRectangle",
                  "bottomRight" : 13
                },
                {
                  "lineWidth" : 3,
                  "attributeName" : "roundedRectangleStroke",
                  "g" : 1,
                  "cornerRadius" : 13,
                  "a" : 1,
                  "b" : 1,
                  "r" : 1
                },
                {
                  "height" : "medium",
                  "attributeName" : "offset"
                },
                {
                  "top" : "none",
                  "bottom" : "none",
                  "attributeName" : "padding",
                  "leading" : "huge",
                  "trailing" : "none"
                }
              ],
              "componentName" : "image",
              "placeholderImage" : "profilePic"
            }
          ],
          "attributes" : [

          ],
          "horizontalAlignment" : "leading",
          "componentName" : "verticalStack"
        }
      ],
      "placeholderImage" : "banner",
      "imageID" : "504e8f11-24eb-4c39-b339-4d7779dcd3a7"
    },
    {
      "attributes" : [
        {
          "top" : "bannerHuge",
          "bottom" : "none",
          "leading" : "none",
          "attributeName" : "padding",
          "trailing" : "none"
        }
      ],
      "children" : [
        {
          "editingLabel" : "Seu Nome aqui",
          "componentName" : "text",
          "children" : [

          ],
          "attributes" : [
            {
              "alignment" : "leading",
              "attributeName" : "frameMaxWidth"
            },
            {
              "attributeName" : "padding",
              "leading" : "huge",
              "top" : "none",
              "trailing" : "huge",
              "bottom" : "none"
            },
            {
              "attributeName" : "font",
              "type" : "Montserrat",
              "size" : "title2"
            },
            {
              "weight" : "semibold",
              "attributeName" : "fontWeight"
            },
            {
              "attributeName" : "color",
              "a" : 1,
              "g" : 1,
              "b" : 1,
              "r" : 1
            }
          ],
          "text" : "Eminem"
        },
        {
          "verticalAlignment" : "center",
          "attributes" : [
            {
              "attributeName" : "padding",
              "leading" : "huge",
              "trailing" : "huge",
              "bottom" : "none",
              "top" : "none"
            }
          ],
          "componentName" : "horizontalStack",
          "children" : [
            {
              "systemName" : "guitars.fill",
              "attributes" : [
                {
                  "attributeName" : "scaledToFit"
                },
                {
                  "attributeName" : "frame",
                  "width" : "tiny",
                  "height" : "tiny"
                },
                {
                  "g" : 1,
                  "attributeName" : "color",
                  "b" : 1,
                  "r" : 1,
                  "a" : 1
                }
              ],
              "componentName" : "sfSymbol",
              "children" : [

              ]
            },
            {
              "componentName" : "text",
              "editingLabel" : "Sua Skill aqui",
              "attributes" : [
                {
                  "type" : "Montserrat",
                  "size" : "callout",
                  "attributeName" : "font"
                },
                {
                  "attributeName" : "fontWeight",
                  "weight" : "medium"
                },
                {
                  "alignment" : "leading",
                  "attributeName" : "frameMaxWidth"
                },
                {
                  "a" : 1,
                  "r" : 1,
                  "attributeName" : "color",
                  "g" : 1,
                  "b" : 1
                }
              ],
              "text" : "Rapper Mundialmente Famoso",
              "children" : [

              ]
            }
          ]
        },
        {
          "attributes" : [
            {
              "top" : "none",
              "attributeName" : "padding",
              "leading" : "huge",
              "trailing" : "huge",
              "bottom" : "none"
            }
          ],
          "verticalAlignment" : "center",
          "className" : "tagContainer",
          "componentName" : "horizontalStack",
          "controller" : {
            "tags" : [

            ],
            "controllerName" : "tagController"
          },
          "children" : [

          ]
        },
        {
          "verticalAlignment" : "center",
          "attributes" : [
            {
              "trailing" : "huge",
              "leading" : "huge",
              "top" : "none",
              "bottom" : "base",
              "attributeName" : "padding"
            }
          ],
          "componentName" : "horizontalStack",
          "children" : [
            {
              "children" : [

              ],
              "systemName" : "mappin",
              "attributes" : [
                {
                  "attributeName" : "scaledToFit"
                },
                {
                  "attributeName" : "frame",
                  "width" : "tiny",
                  "height" : "tiny"
                },
                {
                  "r" : 1,
                  "a" : 1,
                  "attributeName" : "color",
                  "b" : 1,
                  "g" : 1
                }
              ],
              "componentName" : "sfSymbol"
            },
            {
              "text" : "Estados Unidos da América",
              "editingLabel" : "Seu Local aqui",
              "children" : [

              ],
              "componentName" : "text",
              "attributes" : [
                {
                  "attributeName" : "font",
                  "size" : "callout",
                  "type" : "Montserrat"
                },
                {
                  "weight" : "regular",
                  "attributeName" : "fontWeight"
                },
                {
                  "b" : 1,
                  "a" : 1,
                  "r" : 1,
                  "attributeName" : "color",
                  "g" : 1
                },
                {
                  "attributeName" : "frameMaxWidth",
                  "alignment" : "leading"
                }
              ]
            }
          ]
        }
      ],
      "horizontalAlignment" : "leading",
      "componentName" : "verticalStack"
    }
  ],
  "viewType" : "header"
}

const videoContainer = {
  "componentName" : "verticalStack",
  "horizontalAlignment" : "leading",
  "viewType" : "video",
  "attributes" : [

  ],
  "children" : [
    {
      "componentName" : "horizontalStack",
      "attributes" : [
        {
          "bottom" : "base",
          "trailing" : "none",
          "attributeName" : "padding",
          "leading" : "none",
          "top" : "base"
        }
      ],
      "children" : [
        {
          "componentName" : "sfSymbol",
          "attributes" : [
            {
              "attributeName" : "scaledToFit"
            },
            {
              "attributeName" : "frame",
              "width" : "tiny",
              "height" : "tiny"
            }
          ],
          "systemName" : "video.fill",
          "children" : [

          ]
        },
        {
          "attributes" : [
            {
              "type" : "Montserrat",
              "size" : "headline",
              "attributeName" : "font"
            },
            {
              "weight" : "medium",
              "attributeName" : "fontWeight"
            },
            {
              "r" : 1,
              "g" : 1,
              "b" : 1,
              "attributeName" : "color",
              "a" : 1
            }
          ],
          "text" : "Videos",
          "componentName" : "fixedText",
          "children" : [

          ]
        },
        {
          "attributes" : [

          ],
          "children" : [

          ],
          "componentName" : "spacer"
        }
      ],
      "verticalAlignment" : "center"
    },
    {
      "attributes" : [

      ],
      "componentName" : "youtubeVideo",
      "children" : [

      ],
      "youtubeURL" : "https://www.youtube.com/watch?v=xFYQQPAOz7Y"
    }
  ],
  "editingMode" : "youtubeVideoView"
};

const photoGrid = {
  "children" : [
    {
      "children" : [
        {
          "componentName" : "sfSymbol",
          "children" : [

          ],
          "systemName" : "camera.fill",
          "attributes" : [
            {
              "attributeName" : "scaledToFit"
            },
            {
              "attributeName" : "frame",
              "width" : "tiny",
              "height" : "tiny"
            }
          ]
        },
        {
          "children" : [

          ],
          "attributes" : [
            {
              "attributeName" : "font",
              "size" : "headline",
              "type" : "Montserrat"
            },
            {
              "weight" : "medium",
              "attributeName" : "fontWeight"
            },
            {
              "r" : 1,
              "g" : 1,
              "b" : 1,
              "attributeName" : "color",
              "a" : 1
            }
          ],
          "text" : "Fotos",
          "componentName" : "fixedText"
        },
        {
          "componentName" : "spacer",
          "attributes" : [

          ],
          "children" : [

          ]
        }
      ],
      "componentName" : "horizontalStack",
      "attributes" : [
        {
          "trailing" : "none",
          "leading" : "huge",
          "bottom" : "none",
          "attributeName" : "padding",
          "top" : "none"
        }
      ],
      "verticalAlignment" : "center"
    },
    {
      "attributes" : [

      ],
      "componentName" : "scrollView",
      "direction" : "horizontal",
      "children" : [
        {
          "componentName" : "horizontalStack",
          "attributes" : [
            {
              "top" : "none",
              "bottom" : "none",
              "trailing" : "none",
              "leading" : "huge",
              "attributeName" : "padding"
            }
          ],
          "children" : [
            {
              "editingMode" : "imageView",
              "componentName" : "verticalStack",
              "horizontalAlignment" : "center",
              "attributes" : [
                {
                  "width" : "big",
                  "attributeName" : "frame"
                },
                {
                  "trailing" : "big",
                  "top" : "big",
                  "attributeName" : "padding",
                  "leading" : "big",
                  "bottom" : "big"
                },
                {
                  "r" : 0.122,
                  "attributeName" : "background",
                  "g" : 0.122,
                  "b" : 0.122,
                  "a" : 1
                },
                {
                  "topRight" : 21,
                  "bottomRight" : 21,
                  "bottomLeft" : 21,
                  "topLeft" : 21,
                  "attributeName" : "clipShapeRectangle"
                },
                {
                  "radius" : 4,
                  "attributeName" : "shadow",
                  "r" : 0,
                  "b" : 0,
                  "g" : 0,
                  "a" : 0.25
                },
                {
                  "attributeName" : "onTapGesture",
                  "function" : "editMode"
                }
              ],
              "children" : [
                {
                  "componentName" : "image",
                  "placeholderImage" : "polaroidPhoto",
                  "children" : [

                  ],
                  "attributes" : [
                    {
                      "attributeName" : "scaledToFill"
                    },
                    {
                      "attributeName" : "frameReleaseConstraints"
                    },
                    {
                      "attributeName" : "clipped"
                    },
                    {
                      "height" : "big",
                      "attributeName" : "frame"
                    },
                    {
                      "bottomLeft" : 0,
                      "topRight" : 8,
                      "topLeft" : 8,
                      "attributeName" : "clipShapeRectangle",
                      "bottomRight" : 0
                    },
                    {
                      "g" : 0,
                      "b" : 0,
                      "a" : 0.4,
                      "radius" : 4,
                      "attributeName" : "shadow",
                      "r" : 0
                    }
                  ]
                },
                {
                  "editingLabel" : "",
                  "componentName" : "text",
                  "attributes" : [
                    {
                      "type" : "ShadowsIntoLight",
                      "size" : "title3",
                      "attributeName" : "font"
                    },
                    {
                      "attributeName" : "color",
                      "a" : 1,
                      "g" : 1,
                      "b" : 1,
                      "r" : 1
                    },
                    {
                      "attributeName" : "multilineTextAlignment",
                      "alignment" : "center"
                    },
                    {
                      "attributeName" : "lineLimit",
                      "number" : 2
                    }
                  ],
                  "text" : "Conte a história dessa foto!",
                  "children" : [

                  ]
                }
              ]
            },
            {
              "componentName" : "verticalStack",
              "editingMode" : "imageView",
              "attributes" : [
                {
                  "width" : "big",
                  "attributeName" : "frame"
                },
                {
                  "leading" : "big",
                  "trailing" : "big",
                  "attributeName" : "padding",
                  "bottom" : "big",
                  "top" : "big"
                },
                {
                  "r" : 0.122,
                  "b" : 0.122,
                  "a" : 1,
                  "attributeName" : "background",
                  "g" : 0.122
                },
                {
                  "topLeft" : 21,
                  "bottomLeft" : 21,
                  "attributeName" : "clipShapeRectangle",
                  "bottomRight" : 21,
                  "topRight" : 21
                },
                {
                  "attributeName" : "shadow",
                  "b" : 0,
                  "g" : 0,
                  "r" : 0,
                  "a" : 0.25,
                  "radius" : 4
                },
                {
                  "function" : "editMode",
                  "attributeName" : "onTapGesture"
                }
              ],
              "horizontalAlignment" : "center",
              "children" : [
                {
                  "children" : [

                  ],
                  "componentName" : "image",
                  "attributes" : [
                    {
                      "attributeName" : "scaledToFill"
                    },
                    {
                      "attributeName" : "frameReleaseConstraints"
                    },
                    {
                      "attributeName" : "clipped"
                    },
                    {
                      "attributeName" : "frame",
                      "height" : "big"
                    },
                    {
                      "topLeft" : 8,
                      "bottomLeft" : 0,
                      "topRight" : 8,
                      "attributeName" : "clipShapeRectangle",
                      "bottomRight" : 0
                    },
                    {
                      "g" : 0,
                      "r" : 0,
                      "b" : 0,
                      "attributeName" : "shadow",
                      "radius" : 4,
                      "a" : 0.4
                    }
                  ],
                  "placeholderImage" : "polaroidPhoto"
                },
                {
                  "children" : [

                  ],
                  "attributes" : [
                    {
                      "type" : "ShadowsIntoLight",
                      "size" : "title3",
                      "attributeName" : "font"
                    },
                    {
                      "attributeName" : "color",
                      "r" : 1,
                      "g" : 1,
                      "a" : 1,
                      "b" : 1
                    },
                    {
                      "attributeName" : "multilineTextAlignment",
                      "alignment" : "center"
                    },
                    {
                      "number" : 2,
                      "attributeName" : "lineLimit"
                    }
                  ],
                  "text" : "Conte a história dessa foto!",
                  "editingLabel" : "",
                  "componentName" : "text"
                }
              ]
            },
            {
              "horizontalAlignment" : "center",
              "editingMode" : "imageView",
              "children" : [
                {
                  "children" : [

                  ],
                  "attributes" : [
                    {
                      "attributeName" : "scaledToFill"
                    },
                    {
                      "attributeName" : "frameReleaseConstraints"
                    },
                    {
                      "attributeName" : "clipped"
                    },
                    {
                      "attributeName" : "frame",
                      "height" : "big"
                    },
                    {
                      "bottomLeft" : 0,
                      "topRight" : 8,
                      "topLeft" : 8,
                      "bottomRight" : 0,
                      "attributeName" : "clipShapeRectangle"
                    },
                    {
                      "g" : 0,
                      "r" : 0,
                      "a" : 0.4,
                      "radius" : 4,
                      "attributeName" : "shadow",
                      "b" : 0
                    }
                  ],
                  "placeholderImage" : "polaroidPhoto",
                  "componentName" : "image"
                },
                {
                  "componentName" : "text",
                  "text" : "Conte a história dessa foto!",
                  "children" : [

                  ],
                  "attributes" : [
                    {
                      "type" : "ShadowsIntoLight",
                      "attributeName" : "font",
                      "size" : "title3"
                    },
                    {
                      "r" : 1,
                      "a" : 1,
                      "g" : 1,
                      "b" : 1,
                      "attributeName" : "color"
                    },
                    {
                      "attributeName" : "multilineTextAlignment",
                      "alignment" : "center"
                    },
                    {
                      "number" : 2,
                      "attributeName" : "lineLimit"
                    }
                  ],
                  "editingLabel" : ""
                }
              ],
              "componentName" : "verticalStack",
              "attributes" : [
                {
                  "attributeName" : "frame",
                  "width" : "big"
                },
                {
                  "top" : "big",
                  "leading" : "big",
                  "trailing" : "big",
                  "attributeName" : "padding",
                  "bottom" : "big"
                },
                {
                  "g" : 0.122,
                  "r" : 0.122,
                  "a" : 1,
                  "attributeName" : "background",
                  "b" : 0.122
                },
                {
                  "topRight" : 21,
                  "topLeft" : 21,
                  "attributeName" : "clipShapeRectangle",
                  "bottomRight" : 21,
                  "bottomLeft" : 21
                },
                {
                  "b" : 0,
                  "attributeName" : "shadow",
                  "a" : 0.25,
                  "radius" : 4,
                  "g" : 0,
                  "r" : 0
                },
                {
                  "attributeName" : "onTapGesture",
                  "function" : "editMode"
                }
              ]
            }
          ],
          "verticalAlignment" : "center"
        }
      ]
    }
  ],
  "componentName" : "verticalStack",
  "viewType" : "photoGrid",
  "horizontalAlignment" : "leading",
  "attributes" : [

  ]
};

const socialMedia = {
  "attributes" : [

  ],
  "horizontalAlignment" : "center",
  "children" : [
    {
      "horizontalAlignment" : "leading",
      "children" : [
        {
          "attributes" : [

          ],
          "children" : [
            {
              "children" : [

              ],
              "attributes" : [
                {
                  "attributeName" : "scaledToFit"
                },
                {
                  "attributeName" : "frame",
                  "width" : "tiny",
                  "height" : "tiny"
                }
              ],
              "systemName" : "message.fill",
              "componentName" : "sfSymbol"
            },
            {
              "children" : [

              ],
              "componentName" : "fixedText",
              "attributes" : [
                {
                  "attributeName" : "font",
                  "size" : "headline",
                  "type" : "Montserrat"
                },
                {
                  "attributeName" : "fontWeight",
                  "weight" : "medium"
                },
                {
                  "b" : 1,
                  "a" : 1,
                  "attributeName" : "color",
                  "r" : 1,
                  "g" : 1
                }
              ],
              "text" : "Contacts"
            },
            {
              "attributes" : [

              ],
              "children" : [

              ],
              "componentName" : "spacer"
            }
          ],
          "verticalAlignment" : "center",
          "componentName" : "horizontalStack"
        }
      ],
      "attributes" : [
        {
          "top" : "base",
          "trailing" : "none",
          "leading" : "none",
          "bottom" : "base",
          "attributeName" : "padding"
        }
      ],
      "componentName" : "verticalStack"
    },
    {
      "attributes" : [

      ],
      "children" : [
        {
          "componentName" : "horizontalStack",
          "viewType" : "contacts",
          "verticalAlignment" : "center",
          "children" : [
            {
              "children" : [

              ],
              "attributes" : [
                {
                  "attributeName" : "scaledToFit"
                },
                {
                  "attributeName" : "frame",
                  "width" : "socialImgSmall",
                  "height" : "socialImgSmall"
                },
                {
                  "trailing" : "none",
                  "leading" : "base",
                  "top" : "none",
                  "bottom" : "none",
                  "attributeName" : "padding"
                }
              ],
              "endpoint" : "mmuller.pedro@gmail.com",
              "componentName" : "socialMedia",
              "type" : "email"
            },
            {
              "text" : "mmuller.pedro@gmail.com",
              "children" : [

              ],
              "attributes" : [
                {
                  "type" : "Montserrat",
                  "attributeName" : "font",
                  "size" : "headline"
                },
                {
                  "attributeName" : "fontWeight",
                  "weight" : "medium"
                },
                {
                  "attributeName" : "underline"
                },
                {
                  "a" : 1,
                  "attributeName" : "color",
                  "g" : 0.498,
                  "r" : 0.898,
                  "b" : 0.388
                }
              ],
              "componentName" : "fixedText"
            },
            {
              "attributes" : [

              ],
              "children" : [

              ],
              "componentName" : "spacer"
            }
          ],
          "attributes" : [
            {
              "attributeName" : "frameMaxWidth",
              "alignment" : "center"
            },
            {
              "attributeName" : "padding",
              "top" : "medium",
              "bottom" : "medium",
              "leading" : "medium",
              "trailing" : "medium"
            },
            {
              "b" : 0,
              "a" : 0.4,
              "r" : 0,
              "attributeName" : "background",
              "g" : 0
            },
            {
              "attributeName" : "clipShapeRectangle",
              "topLeft" : 13,
              "bottomLeft" : 13,
              "topRight" : 13,
              "bottomRight" : 13
            },
            {
              "function" : "socialMedia",
              "attributeName" : "onTapGesture"
            }
          ]
        },
        {
          "children" : [
            {
              "componentName" : "socialMedia",
              "attributes" : [
                {
                  "attributeName" : "scaledToFit"
                },
                {
                  "width" : "socialImgSmall",
                  "height" : "socialImgSmall",
                  "attributeName" : "frame"
                },
                {
                  "bottom" : "none",
                  "attributeName" : "padding",
                  "top" : "none",
                  "leading" : "base",
                  "trailing" : "none"
                }
              ],
              "type" : "phone",
              "endpoint" : "5551980290901",
              "children" : [

              ]
            },
            {
              "attributes" : [
                {
                  "attributeName" : "font",
                  "size" : "headline",
                  "type" : "Montserrat"
                },
                {
                  "weight" : "medium",
                  "attributeName" : "fontWeight"
                },
                {
                  "attributeName" : "underline"
                },
                {
                  "b" : 0.388,
                  "g" : 0.498,
                  "r" : 0.898,
                  "attributeName" : "color",
                  "a" : 1
                }
              ],
              "text" : "5551980290901",
              "children" : [

              ],
              "componentName" : "fixedText"
            },
            {
              "componentName" : "spacer",
              "children" : [

              ],
              "attributes" : [

              ]
            }
          ],
          "componentName" : "horizontalStack",
          "attributes" : [
            {
              "alignment" : "center",
              "attributeName" : "frameMaxWidth"
            },
            {
              "bottom" : "medium",
              "attributeName" : "padding",
              "leading" : "medium",
              "top" : "medium",
              "trailing" : "medium"
            },
            {
              "attributeName" : "background",
              "r" : 0,
              "g" : 0,
              "b" : 0,
              "a" : 0.4
            },
            {
              "topLeft" : 13,
              "topRight" : 13,
              "attributeName" : "clipShapeRectangle",
              "bottomRight" : 13,
              "bottomLeft" : 13
            },
            {
              "attributeName" : "onTapGesture",
              "function" : "socialMedia"
            }
          ],
          "viewType" : "contacts",
          "verticalAlignment" : "center"
        }
      ],
      "componentName" : "verticalStack",
      "className" : "socialContainer",
      "horizontalAlignment" : "center"
    }
  ],
  "editingMode" : "socialMediaContainerView",
  "controller" : {
    "sizes" : [
      "rect",
      "rect"
    ],
    "controllerName" : "socialMediaController",
    "medias" : [
      "email",
      "phone"
    ]
  },
  "viewType" : "contacts",
  "componentName" : "verticalStack"
};

const jsonData = {
  "attributes" : [

  ],
  "children" : [
    header,
    description,
    photoGrid, 
    videoContainer,
    socialMedia
  ],
  "b" : 0.388,
  "r" : 0.898,
  "componentName" : "root",
  "g" : 0.498,
  "a" : 1
};

const App = () => {
  const { id } = useParams(); // Extract the id from the URL
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      // Construct the API endpoint using the id from the URL
      const apiEndpoint = `https://dockerapi.bandbookr.uk/portfolios/get/${id}`;
      
      fetch(apiEndpoint)
        .then(response => response.json())
        .then(data => {
          setJsonData(JSON.parse(data.jsonContent)); // Set jsonData with the jsonContent from the response
          setLoading(false); // Stop loading once data is fetched
        })
        .catch(error => {
          console.error("Error fetching portfolio data:", error);
          setLoading(false); // Stop loading in case of an error
        });
    }
  }, [id]); // Fetch data when the id changes

  return (
    <div className="scrollView" style={{ overflowY: "scroll", height: "100vh", width: "100vw", backgroundColor: "#1A1A1A" }}>
      {loading ? (
        <div style={{ color: "white" }}>Loading...</div> // Display loading message while fetching data
      ) : (
        <EPKJsonRenderer data={jsonData} />
      )}
    </div>
  );
};

export default App;